export default [
  {
    groups: [
      {
        title: 'THÔNG TIN',
        attributes: [
          {
            prop: 'name',
            label: 'Tiêu đề',
            type: 'text',
            validate: 'required'
          },
          {
            prop: 'name_en',
            label: 'Tiêu đề (en)',
            type: 'text'
          },
          {
            prop: 'slug',
            label: 'Đường dẫn',
            type: 'text',
            validate: 'required'
          },
          {
            prop: 'link',
            label: 'Link',
            type: 'text'
          },
          {
            prop: 'content',
            label: 'Nội dung',
            type: 'editor'
          },
          {
            prop: 'content_en',
            label: 'Nội dung (en)',
            type: 'editor'
          }
        ]
      }
    ]
  }
]
