<template lang="html">
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-12">
      <form-card
        v-for="(group, index) of centerGroups"
        :group="group"
        :key="index"
        v-model="charity"
      ></form-card>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="card">
        <div class="card-header">
          <h6 class="title">Banner</h6>
        </div>
        <div class="card-body form-card">
          <my-image v-model="charity.banner"></my-image>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h6 class="title">Logo</h6>
        </div>
        <div class="card-body form-card">
          <my-image v-model="charity.image"></my-image>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="page-action">
        <el-button @click="cancel">Quay lại</el-button>
        <el-button type="primary" @click="save">Cập nhật</el-button>
      </div>
    </div>
    <toast position="se"></toast>
  </div>
</template>

<script>
import FormCard from 'src/components/UIComponents/FormCard.vue';
import { Message, Input, Button } from 'element-ui';
import dataFrom from './charity-form';
import { mapState } from 'vuex';
import { Toast } from 'vuex-toast'
import { mapActions } from 'vuex'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import MyImage from 'src/components/UIComponents/Image'

let _form = {}, firstGroups;

export default {

  components: {
    FormCard,
    ElInput: Input,
    ElButton: Button,
    Toast,
    Message,
    MyImage
  },

  beforeCreate() {
    firstGroups = dataFrom[0].groups;

    firstGroups.forEach(group => {
      group.attributes.forEach(attr => {
        if (attr.multiple && _form[attr.prop]) {
          _form[attr.prop] = [];
        } else {
          _form[attr.prop] = '';
        }
        attr.value = _form[attr.prop];
      });
    });

    window.AP = this;
  },

  data() {
    return {
      centerGroups: firstGroups
    }
  },

  computed: {
    charity: {
      get() {
        return this.$store.state.charityDetail
      },
      set(value) {

      }
    }
  },

  mounted() {
    this.$store.dispatch('setPageTitle', 'Chỉnh sửa quỹ từ thiện');
    this.$store.dispatch('setCurrentActions', [{
      label: 'Cập nhật',
      type: 'primary',
      icon: '',
      callback: this.save
    }])
    let id = this.$route.params.id;
    this.$store.dispatch('fetchCharityDetail', id);
  },
  methods: {
    cancel() {
      this.$router.push({name: 'Charity'});
    },
    save() {
      let self = this;
      let data = self.charity;
      this.$store.dispatch('updateCharity', data).then(res => {
        if (res.success) {
          Message({
            message: 'Cập nhật thành công',
            type: 'success'
          });
        } else {
          if (res.errors) {
            for (let err in res.errors) {
              Message({
                message: res.errors[err][0],
                type: 'error'
              });
            }
          } else {
            Message({
              message: res.message,
              type: 'error'
            });
          }
        }
      }, error => {
        console.error(error);
      });
    },
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    toast(text, type) {
      this.addToast({
        text,
        type: type,
        dismissAfter: 3000
      })
    }
  },
  destroyed(){
    this.$store.dispatch('setCurrentActions', []);
    this.$store.state.charityDetail = [];
  }
}
</script>
